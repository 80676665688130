import "twin.macro";

import { useMatch, useNavigate } from "react-router-dom";
import { redirectToAuth } from "supertokens-auth-react";

import { NeuButton } from "./generic/Neu";
import Session from "supertokens-auth-react/recipe/session";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ChangeLanguage } from "@/translation/ChangeLanguage";

function LandingPage(): JSX.Element {
  const [checkedSession, setCheckedSession] = useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const routeMatch = useMatch({
    path: "/",
  });

  useEffect(() => {
    if (!checkedSession) {
      (async () => {
        if (await Session.doesSessionExist()) {
          let userId = await Session.getUserId();
          if (userId !== undefined && routeMatch) {
            navigate(`/collection/`, { replace: true });
          }
        }
        setCheckedSession(true);
      })();
    }
  }, [navigate, routeMatch, checkedSession, setCheckedSession]);

  return (
    <div tw="w-full max-w-screen-md mx-auto py-20">
      <div tw="flex justify-end">
        <ChangeLanguage i18n={i18n} />
      </div>
      <Trans
        i18nKey="landingPage.fulltext"
        components={{ h1: <h1 />, b: <b />, h2: <h2 tw="mt-12" /> }} // eslint-disable-line
      />

      <p tw="text-center">
        <NeuButton
          onClick={() => {
            redirectToAuth({ redirectBack: false });
          }}
          tw="text-white hover:bg-positive-dark bg-positive-darkest"
        >
          {t("landingPage.loginButton")}
        </NeuButton>
      </p>
    </div>
  );
}

export default LandingPage;
