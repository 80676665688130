import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import english from "../translation/locales/en/en.json";
import german from "../translation/locales/de/de.json";

i18n.use(LanguageDetector).init({
  fallbackLng: "en",
  debug: true,
  load: "languageOnly",
  ns: "translations",
  defaultNS: "translations",
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    useSuspense: true,
  },
  resources: {
    en: {
      //translator will specifically look for 'namespace' (key in this object) "translations" (set above) unless otherwise told in the hook
      translations: english,
    },
    de: {
      translations: german,
    },
  },
});

export default i18n;
