import "twin.macro";
import React, { startTransition, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useAssets, useDeleteAlbum } from "../../clients/apiHooks";
import { useAlbum } from "../../clients/apiHooks";
import { useIntersectionObserver } from "../../hooks";

import { Modal } from "../generic";
import { ErrorBoundary } from "@sentry/react";
import { MenuContainer, NeuButton } from "../generic/Neu";

import Uploads from "./Uploads";
import { UploadList } from "./UploadList";
import { ItemGroupContainer, TagItem } from "../tags/Items";
import { EditableTitle } from "./EditableTitle";
import { resetPageParam } from "../collection/AlbumList";
import { albumViewPageSize, getAssets } from "../../clients/apiClient";
import { getPage, Pagination } from "../generic/Pagination";
import SearchInput from "../generic/SearchInput";
import { SortOrder } from "../../clients/types";
import AlbumsSharedWith from "./AlbumSharedInList";
import { AssetsDisplayList } from "../generic/DisplayList";
import { triggerFileDownload } from "../../utils";
import { SuspenseWrapper } from "../generic/SuspenseWrapper";
import { AppTitle } from "../Title";
import ConfirmDialog from "../share/confirmDialog";

interface AlbumProps {
  shareId?: string;
  sortOrder: SortOrder;
  setSortOrder: (sortOrder: SortOrder) => void;
  pilledSearchQueries: string[];
  setPilledSearchQueries: (pilledSearchQueries: string[]) => void;
}

export function getSortOrderLabel(sortOrder: string) {
  return sortOrder === "ASC" ? "A-Z" : "Z-A";
}

function AlbumViewInner({
  shareId,
  sortOrder,
  setSortOrder,
  pilledSearchQueries,
  setPilledSearchQueries,
}: AlbumProps) {
  const { collectionId, albumId, assetId } = useParams<{
    collectionId: string;
    albumId: string;
    assetId: string;
  }>();
  const [searchParams, setSearchParams] = useSearchParams();
  // FIXME: the way the query params work for pagination is kind of a mess because the album view still also has the album list
  const page = getPage(searchParams, "assetPage");

  const [showUpload /*, setShowUpload*/] = useState(false);

  const isFetchEnable = !assetId;

  const { assets: albumAssets } = useAssets({
    collectionId: collectionId!, //I dont believe there is a scenario where collection id is not present
    albumId,
    sortOrder,
    pilledSearchQueries,
    offset: (page - 1) * albumViewPageSize,
    shareId,
    isFetchEnable,
  });

  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(false);

  const { patchAlbum, album } = useAlbum(
    collectionId!,
    albumId!,
    isFetchEnable,
  );
  const { deleteAlbum } = useDeleteAlbum(collectionId!);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const pages = Math.max(
    Math.ceil(albumAssets.assetsCount / albumViewPageSize),
    1,
  );

  const { isVisible } = useIntersectionObserver();

  const [prevPilledSearchQueries, setPrevPilledSearchQueries] = useState<
    string[]
  >([]);

  if (pilledSearchQueries !== prevPilledSearchQueries) {
    // we intentionally don't add searchParams here, doing so would make it impossible to ever change the page!
    resetPageParam({ searchParams, setSearchParams, name: "assetPage" });
    setPrevPilledSearchQueries(pilledSearchQueries);
  }

  if (!album || !albumId || !collectionId) {
    return null;
  }

  const handleNewTag = album.isOwner
    ? async (update: { key: string; value: string }) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          tags: [
            ...(album.tags || []),
            { key: update.key, value: update.value },
          ],
        };
        await patchAlbum.mutate(dataPayload);
      }
    : undefined;

  const handleUpdateAlbumName = album.isOwner
    ? async (newAlbumName: string) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          name: newAlbumName,
        };
        await patchAlbum.mutate(dataPayload);
      }
    : undefined;

  async function handleDeleteAlbum() {
    if (!album) {
      return;
    }
    await deleteAlbum.mutate(album.id);
    navigate(`/collection/${collectionId}`);
  }

  async function handleDownloadAll() {
    if (isDownloading || !collectionId) {
      return;
    }

    setIsDownloading(true);

    try {
      const _albumId = albumId;
      const _collectionId = collectionId;
      let offset = 0;
      const limit = 10;
      let assetCount;
      do {
        const res = await getAssets({
          albumId: _albumId,
          collectionId: _collectionId,
          limit,
          offset,
        });
        if (assetCount === undefined) assetCount = res.assetsCount;
        if (assetCount === 0 || assetCount > 50) return; // TODO: Notify user
        for (const a of res.assets) {
          triggerFileDownload(a.download_path);
        }
        offset += limit;
        // Delay between batches is necessary in Chrome
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } while (offset < assetCount);
    } catch (error) {
      console.error("Error downloading all assets:", error); // TODO: Notify user
    } finally {
      setIsDownloading(false);
    }
  }

  const handleUpdateTag = album.isOwner
    ? (
        tag: { key: string; value: string },
        update: { key: string; value: string },
      ) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          tags: album.tags.map((t) =>
            t.key === tag.key && t.value === tag.value
              ? { key: update.key, value: update.value }
              : t,
          ),
        };
        patchAlbum.mutate(dataPayload);
      }
    : undefined;

  const handleDeleteTag = album.isOwner
    ? (tag?: { key: string; value: string }) => {
        if (!album) {
          return;
        }
        const dataPayload = {
          tags: album.tags.filter(
            (t) => t.key !== tag?.key || t.value !== tag?.value,
          ),
        };
        patchAlbum.mutate(dataPayload);
      }
    : undefined;

  return (
    <ErrorBoundary fallback={<div>something went wrong loading albums...</div>}>
      <AppTitle contentTitle={album.name} />
      <div tw="max-w-[1440px]">
        {showUpload && (
          <Modal>
            <Uploads albumId={album?.id} collectionId={album?.collectionId} />
          </Modal>
        )}
        <div tw="sm:space-y-12 mt-8">
          <div id="headerDiv" tw="flex flex-row justify-between">
            <div tw="w-full">
              <span tw="label">Album</span>
              <div tw="flex flex-col sm:flex-row gap-4 justify-between flex-wrap w-full">
                <div tw="text-3xl sm:text-7xl max-w-full">
                  <EditableTitle
                    title={album.name}
                    onUpdate={handleUpdateAlbumName}
                  />
                </div>
                <NeuButton
                  tw="bg-danger text-sm mb-6 sm:mb-0 mr-auto sm:mr-0"
                  icon="mi-delete"
                  onClick={() => setIsDeleteDialogOpen(true)}
                >
                  Delete Album
                </NeuButton>
              </div>
            </div>
            {/*
                <div>
                  <UploadList
                    current={false}
                    albumId={albumId}
                    collectionId={album?.collectionId}
                  />
                </div>
                */}
          </div>
          <div tw="space-y-2 mb-4">
            <span tw="label mb-4">Tags</span>
            <ItemGroupContainer tw="-ml-1">
              {album.labels?.map((label) => (
                <TagItem
                  key={"#" + label}
                  tag={{ key: "#", value: label }}
                  onUpdate={handleUpdateTag}
                  onDelete={handleDeleteTag}
                />
              ))}
              {album.tags?.map((t) => (
                <TagItem
                  key={t.key + t.value}
                  tag={t}
                  onUpdate={handleUpdateTag}
                  onDelete={handleDeleteTag}
                />
              ))}
              <TagItem key="new-tag-input" onCreate={handleNewTag} />
            </ItemGroupContainer>
          </div>
          {album.isOwner && (
            <SuspenseWrapper>
              <AlbumsSharedWith collectionId={collectionId} albumId={albumId} />
            </SuspenseWrapper>
          )}
          <div>
            <div tw="mb-4 sticky top-12 z-10">
              <MenuContainer highlight={!isVisible}>
                <div tw="flex flex-col sm:flex-row gap-4 sm:gap-x-8 w-full">
                  <SearchInput
                    collectionId={collectionId}
                    shareId={shareId}
                    setPilledSearchQueries={setPilledSearchQueries}
                  />
                  <div tw="flex flex-wrap justify-between gap-4">
                    <NeuButton
                      onClick={() =>
                        startTransition(
                          () =>
                            setSortOrder &&
                            setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC"),
                        )
                      }
                    >
                      <i className="mi-sort" /> {getSortOrderLabel(sortOrder)}
                    </NeuButton>
                    <Uploads
                      albumId={album?.id}
                      collectionId={album?.collectionId}
                    />
                    <NeuButton
                      icon="mi-download"
                      onClick={handleDownloadAll}
                      disabled={isDownloading}
                    >
                      {isDownloading ? `Downloading...` : "Download all"}
                    </NeuButton>
                  </div>
                </div>
                <UploadList
                  current
                  albumId={albumId}
                  collectionId={collectionId}
                />
              </MenuContainer>
            </div>
            <div>
              <AssetsDisplayList
                assets={albumAssets.assets}
                pilledSearchQueries={pilledSearchQueries}
                page={page}
              />
              <Pagination pages={pages} param="assetPage" tw="mt-4" />
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        isOpen={isDeleteDialogOpen}
        onAccept={handleDeleteAlbum}
        onDecline={() => setIsDeleteDialogOpen(false)}
        body={
          <>
            {`Are you sure you want to delete this album and its
                ${albumAssets.assetsCount} asset${
                  albumAssets.assetsCount !== 1 ? "s" : ""
                }`}
            <br /> This action cannot be undone.
          </>
        }
        confirmText={"Yes, delete album"}
        declineText={"No, take me back"}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </ErrorBoundary>
  );
}

function AlbumView({
  shareId,
  sortOrder,
  setSortOrder,
  pilledSearchQueries,
  setPilledSearchQueries,
}: AlbumProps) {
  return (
    <ErrorBoundary fallback={<div>something went wrong loading albums...</div>}>
      <SuspenseWrapper fallback={<AlbumViewSkeletons />}>
        <AlbumViewInner
          shareId={shareId}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          pilledSearchQueries={pilledSearchQueries}
          setPilledSearchQueries={setPilledSearchQueries}
        />
      </SuspenseWrapper>
    </ErrorBoundary>
  );
}

function ImageSkeleton() {
  return (
    <div tw="flex animate-pulse w-full">
      <div tw="w-52 h-52 sm:w-72 sm:h-72 bg-grey-light rounded-md animate-pulse "></div>
      <div tw="ml-2 w-8 h-52 sm:h-72 bg-grey-light rounded-md animate-pulse "></div>
    </div>
  );
}

const AlbumViewSkeletons = () => {
  return (
    <div tw="sm:space-y-12 mt-8">
      <div tw="w-full">
        <div tw="w-[15%] sm:w-[7%] h-6 bg-grey-light animate-pulse mb-4" />
        <div tw="flex flex-col sm:flex-row gap-4 justify-between flex-wrap w-full">
          <div tw="w-64 h-10 sm:h-16 bg-grey-light animate-pulse" />
          <span tw="w-32 h-10 bg-grey-light animate-pulse rounded-lg" />
        </div>
      </div>
      <div tw="space-y-2 my-4">
        <div tw="w-[12%] sm:w-[5%] h-6 bg-grey-light animate-pulse mb-4" />
        <div tw="flex space-x-2">
          <span tw="w-[30%] sm:w-[10%] h-10 bg-grey-light animate-pulse" />
          <span tw="w-[30%] sm:w-[10%] h-10 bg-grey-light animate-pulse" />
          <span tw="w-[30%] sm:w-[10%] h-10 bg-grey-light animate-pulse" />
        </div>
      </div>
      <div tw="space-y-2 my-4">
        <div tw="w-[100%] sm:w-[80%] h-24 sm:h-20 bg-grey-light animate-pulse rounded-lg" />
      </div>
      <div>
        <div tw="w-[15%] sm:w-[7%] h-6 bg-grey-light animate-pulse mb-4" />
        <div tw="grid grid-cols-2 sm:grid-cols-3 gap-6">
          {Array.from({ length: 3 }).map((_, i) => (
            <ImageSkeleton key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AlbumView);
