import React, { forwardRef } from "react";
import "twin.macro";
import { AlbumLink, RichAlbumLink } from "../collection/AlbumList";
import { Album, Asset } from "../../clients/types";
import AssetTile from "../album/AssetTile";
import { SuspenseWrapper } from "./SuspenseWrapper";

interface AlbumDisplayListProps {
  albums: Album[];
  isCondensed?: boolean;
  page: number;
  pilledSearchQueries?: string[];
  shareId?: string;
}
const NoMatchesMessage = ({
  pilledSearchQueries,
}: {
  pilledSearchQueries: string[];
}) => {
  const queryText =
    pilledSearchQueries.length > 0
      ? pilledSearchQueries.join(", ")
      : "no search queries";

  return <p>The search returned no matches for "{queryText}"</p>;
};

export const AlbumDisplayList = forwardRef<
  HTMLSpanElement,
  AlbumDisplayListProps
>(({ albums, isCondensed, page, pilledSearchQueries, shareId }, ref) => {
  if (pilledSearchQueries?.length && albums.length === 0) {
    return <NoMatchesMessage pilledSearchQueries={pilledSearchQueries} />;
  }

  if (albums.length === 0) {
    return <div>This collection contains no albums.</div>;
  }

  return (
    <div>
      <span ref={ref} tw="label mb-4">
        Albums
      </span>
      <div tw="space-y-4">
        {albums.map((a) => (
          <SuspenseWrapper
            key={a.id}
            fallback={<AlbumLink album={a} shareId={shareId} />}
          >
            <RichAlbumLink
              album={a}
              isCondensed={isCondensed}
              pageNumber={page}
              shareId={shareId}
            />
          </SuspenseWrapper>
        ))}
      </div>
    </div>
  );
});

export function AssetsDisplayList({
  assets,
  pilledSearchQueries,
  page,
}: {
  assets: Asset[];
  pilledSearchQueries?: string[];
  page: number;
}) {
  const renderContent = () => {
    if (pilledSearchQueries?.length && assets.length === 0) {
      return <NoMatchesMessage pilledSearchQueries={pilledSearchQueries} />;
    }

    if (assets.length === 0) {
      return <div>This Album contains no assets.</div>;
    }

    if (assets.length > 0) {
      return assets.map((asset) => (
        <AssetItem key={asset.id} asset={asset} page={page} />
      ));
    }

    return null;
  };

  return (
    <div>
      <span tw="label mt-6 mb-4">Assets</span>
      <div tw="grid grid-cols-2 sm:grid-cols-3 gap-6">{renderContent()}</div>
    </div>
  );
}

const AssetItem = ({ asset, page }: { asset: Asset; page: number }) => (
  <div tw="flex">
    <AssetTile asset={asset} page={page} />
    <div tw="w-10 relative overflow-hidden">
      <span tw="pl-4 absolute bottom-0 left-0 inline-block text-sm font-bold -rotate-90 origin-bottom-left whitespace-nowrap translate-x-6">
        {asset.name}
      </span>
    </div>
  </div>
);
