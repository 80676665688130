import { Checkbox } from "@/components/ui/checkbox";

interface CheckboxWithTextProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: () => void;
}

export function CheckboxWithText({
  id,
  label,
  checked,
  onChange,
}: CheckboxWithTextProps) {
  return (
    <div className="items-top flex space-x-2 mt-1">
      <Checkbox id={id} checked={checked} onCheckedChange={onChange} />
      <div className="grid gap-1.5 leading-none">
        <label
          htmlFor="terms1"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          {label}
        </label>
      </div>
    </div>
  );
}
