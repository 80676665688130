import React from "react";
import i18next from "i18next";
import { Button } from "@/components/ui/button";

export const ChangeLanguage = ({ i18n }: { i18n: typeof i18next }) => {
  const currentLang = i18n.language;
  return (
    <div className="flex gap-4">
      <Button
        disabled={currentLang === "en"}
        onClick={() => i18n.changeLanguage("en")}
      >
        EN
      </Button>
      <Button
        disabled={currentLang === "de"}
        onClick={() => i18n.changeLanguage("de")}
      >
        DE
      </Button>
    </div>
  );
};
